import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as GoogleIcon } from '../../assets/icons/google_icon.svg';
import Alert from '../../components/Alert/Alert';
import LeftColumn from '../../components/Col/LeftColumn/LeftColumn';

interface AlertProps {
    message: string;
    onClose: () => void;
}

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

const SignupPage: React.FC = () => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState(false);

    const [success, setSuccess] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [countdown, setCountdown] = useState(5);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (firstName.length > 500) {
            setError('First name cannot exceed 500 characters');
            setIsSubmitting(false);
            return;
        }

        if (lastName.length > 500) {
            setError('Last name cannot exceed 500 characters');
            setIsSubmitting(false);
            return;
        }

        if (email.length > 500) {
            setError('Email cannot exceed 500 characters');
            setIsSubmitting(false);
            return;
        }

        if (password.length < 8) {
            setError('Password must be at least 8 characters');
            setIsSubmitting(false);
            return;
        }

        if (password !== repeatPassword) {
            setError('Passwords do not match');
            setIsSubmitting(false);
            return;
        }

        try {
            const apiEndpoint = process.env.REACT_APP_BE_API_ENDPOINT;
            const response = await axios.post(`${apiEndpoint}/api/auth/signup`, {
                firstname: `${firstName}`,
                lastname: `${lastName}`,
                email,
                password,
            });

            setSuccess('Account has been created successfully. Please check your email to verify it before signing in.');
            setError(null);

            console.log(response.data);

            const timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        navigate('/signin');
                        clearInterval(timer);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        } catch (error) {

            const err = error as { message: string; response?: { status: number; } };
            console.log(err.message)

            if (err.response?.status === 409) {
                setError('Account already exists for that email. Please sign in.');
            } else {
                setError('Something went wrong. Please try again later.');
            }
            
            setIsSubmitting(false);
            console.error('Signup error:', error);
        }
    };

    // Initiate Google OAuth login
    const handleGoogleLogin = () => {
        const oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=openid%20email%20profile&state=randomStateString`;

        // Redirect the user to Google's OAuth 2.0 consent page
        window.location.href = oauthUrl;
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="flex flex-col md:flex-row w-full max-w-5xl shadow-lg rounded-lg md:min-h-[640px]">
                {/* Left Column */}
                <LeftColumn />

                {/* Right Column */}
                <div className="bg-white p-6 md:w-1/2 w-full rounded-r-lg flex flex-col justify-center">
                    <div className="max-w-md mx-auto">
                        <h2 className="text-center text-2xl font-semibold mb-2">Sign Up</h2>
                        <p className="text-center text-gray-500 mb-6">With social accounts</p>
                        <button
                            onClick={handleGoogleLogin}
                            className="w-full border border-gray-300 py-2 flex items-center justify-center space-x-2 rounded-md hover:bg-gray-100 mb-2 transition duration-200">
                            <GoogleIcon className="w-5 h-5" />
                            <span className="text-gray-700">Sign up with Google</span>
                        </button>
                        <div className="my-4 flex items-center">
                            <span className="border-t border-gray-300 flex-grow"></span>
                            <span className="mx-2 text-gray-500">Or continue with</span>
                            <span className="border-t border-gray-300 flex-grow"></span>
                        </div>
                        {error && <Alert message={error} onClose={() => setError(null)} type="error" />}
                        {success && <Alert message={success} onClose={() => setSuccess(null)} type="success" />}
                        <form className="space-y-4 mt-3" onSubmit={handleSubmit}>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                                />
                            </div>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                            />
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                            />
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    id="showPassword"
                                    checked={showPassword}
                                    onChange={() => setShowPassword(!showPassword)}
                                    className="mr-2"
                                />
                                <label htmlFor="showPassword" className="text-gray-700 text-sm">Show Password</label>
                            </div>
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Repeat Password"
                                value={repeatPassword}
                                onChange={(e) => setRepeatPassword(e.target.value)}
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                            />
                            <div className="flex items-center">
                                <label htmlFor="terms" className="text-gray-700 text-sm italic">
                                    By signing up, you agree to the
                                    <a href="/terms-of-service" className="text-primary hover:text-primary-light"> Terms of Service </a>
                                    and
                                    <a href="/privacy-policy" className="text-primary hover:text-primary-light"> Privacy Policy</a>.
                                </label>
                            </div>
                            <button
                                type="submit"
                                className={`w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <span className="loading loading-spinner"></span>
                                        <span className="italic">Please wait</span>
                                    </div>
                                ) : (
                                    'Sign Up'
                                )}
                            </button>
                            {success && (
                                <div className="flex flex-col items-center mt-3">
                                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-primary mb-2"></div>
                                    <p className="text-center text-gray-500">Redirect to sign-in in {countdown} seconds...</p>
                                </div>
                            )}
                        </form>
                        <p className="mt-4 text-center text-sm text-gray-500">
                            Already have an Account? <a href="/signin" className="text-primary hover:text-primary-light">Sign in</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupPage;