import React from 'react';

const LeftColumn: React.FC = () => {
    return (
        <div className="hidden md:flex flex-col justify-between items-center bg-primary text-white p-5 rounded-l-lg w-full md:w-1/2">
            <div className="flex-grow flex flex-col justify-center items-center">
                <h1 className="text-4xl font-bold">ParseTrue</h1>
                <p className="mt-3 text-xl">Fast, Efficient and Productive</p>
            </div>
            {/* Links at the bottom */}
            <div className="text-center mb-4">
                <a href="#language" className="text-white transition duration-200 mr-3 hover:text-primary-light hover:font-semibold">Home</a>
                <a href="#terms" className="text-white transition duration-200 mr-3 hover:text-primary-light hover:font-semibold">Terms</a>
                <a href="#plans" className="text-white transition duration-200 mr-3 hover:text-primary-light hover:font-semibold">Plans</a>
                <a href="#contact" className="text-white transition duration-200 hover:text-primary-light hover:font-semibold">Contact Us</a>
            </div>
        </div>
    );
};

export default LeftColumn;