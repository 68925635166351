import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import GeneralTab from './Tabs/General/GeneralTab';
import AdditionalFieldsTab from './Tabs/AdditionalFields/AdditionalFieldsTab';
import AdvancedTab from './Tabs/Advanced/AdvancedTab';

import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';

interface GeneralSettings {
    name: string;
    engine: string;
    email_alias: string;
    retention: number;
    include_attachments: boolean;
    split_pdf_on_every: number;
}

interface AdvancedFields {
    include_urls: boolean;
    include_imgs: boolean;
    alert_settings: {
        email_on_failed_doc_every: number;
        email_on_failed_integ_every: number;
    };
}

const InboxSetting: React.FC = () => {
    const { id: inboxId = '' } = useParams<{ id: string }>();
    const [activeTab, setActiveTab] = useState('General');
    const [generalSettings, setGeneralSettings] = useState<GeneralSettings | null>(null);
    const [additionalFields, setAdditionalFields] = useState<any>(null);
    const [advancedFields, setAdvancedFields] = useState<AdvancedFields | null>(null);

    const General = 'General';
    const AdditionalFields = 'Additional Fields';
    const Advanced = 'Advanced';

    useEffect(() => {
        const fetchInboxData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BE_API_ENDPOINT}/api/inbox`, { params: { inbox_id: inboxId } });
                const inbox = response.data.inboxes[0];

                setGeneralSettings({
                    name: inbox.name,
                    engine: inbox.engine,
                    email_alias: inbox.email_alias,
                    retention: inbox.retention,
                    include_attachments: inbox.general_settings.include_attachments,
                    split_pdf_on_every: inbox.general_settings.split_pdf_on_every,
                });

                setAdditionalFields(inbox.meta_settings);

                setAdvancedFields({
                    include_urls: inbox.general_settings.include_urls,
                    include_imgs: inbox.general_settings.include_imgs,
                    alert_settings: inbox.alert_settings,
                });
            } catch (error) {
                console.error('Error fetching inbox data:', error);
            }
        };

        fetchInboxData();
    }, [inboxId]);

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    return (
        <>
            <>  {/* Changed `mx-auto` to left-align */}
                {/* Breadcrumb */}
                <Breadcrumb pageName='Settings' />

                <div className="border-b border-gray-300 mb-4">
                    <ul className="flex gap-6">
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === General ? 'border-b-4 border-gray-700 font-bold text-gray-700' : 'hover:text-gray-700'}`}
                            onClick={() => handleTabClick(General)}
                        >
                            General
                        </li>
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === AdditionalFields ? 'border-b-4 border-gray-700 font-bold text-gray-700' : 'hover:text-gray-700'}`}
                            onClick={() => handleTabClick(AdditionalFields)}
                        >
                            Additional Fields
                        </li>
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === Advanced ? 'border-b-4 border-gray-700 font-bold text-gray-700' : 'hover:text-gray-700'}`}
                            onClick={() => handleTabClick(Advanced)}
                        >
                            Advanced
                        </li>
                    </ul>
                </div>
                {activeTab === General && <GeneralTab generalSettings={generalSettings} inboxId={inboxId} />}
                {activeTab === AdditionalFields && <AdditionalFieldsTab additionalFields={additionalFields} inboxId={inboxId} />}
                {activeTab === Advanced && <AdvancedTab advancedFields={advancedFields} inboxId={inboxId}/>}
            </>
        </>
    );
};

export default InboxSetting;