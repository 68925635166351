import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import axios from 'axios';

interface InboxProps {
    onUpdateSidebarParam: (name: string, emailAlias: string) => void;
}

const Inbox: React.FC<InboxProps> = ({ onUpdateSidebarParam }) => {
    const { id: inboxId } = useParams<{ id: string }>();
    const [name, setName] = useState<string>('');
    const [emailAlias, setEmailAlias] = useState<string>('');

    const fetchInboxDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BE_API_ENDPOINT}/api/inbox`, {
                params: { inbox_id: inboxId },
                withCredentials: true,
            });
            const { name, email_alias } = response.data;
            setName(name);
            setEmailAlias(email_alias);
            onUpdateSidebarParam(name, email_alias);
        } catch (error) {
            console.error('Error fetching inbox details:', error);
        }
    };

    useEffect(() => {
        if (process.env.REACT_APP_LOCAL_TESTING === 'true') {
            // Use dummy data for local testing
            console.log('Using dummy data for local testing');
            const dummyName = 'Dummy Invoices';
            const dummyEmailAlias = 'dumm.inbox';
            setName(dummyName);
            setEmailAlias(dummyEmailAlias);
            onUpdateSidebarParam(dummyName, dummyEmailAlias);
        } else {
            // Fetch data from the API
            fetchInboxDetails();
        }
    }, [inboxId]);

    return (
        <div className="p-5 bg-white rounded-lg shadow w-full overflow-x-hidden">
            <Outlet />
        </div>
    );
};

export default Inbox;
