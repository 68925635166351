import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface AdditionalFieldsTabProps {
    additionalFields: { [key: string]: boolean } | null;
    inboxId: string;
}

const AdditionalFieldsTab: React.FC<AdditionalFieldsTabProps> = ({ additionalFields, inboxId }) => {
    const fields = [
        { label: 'Sender email', description: "Sender's email: 'From' field", key: 'from' },
        { label: 'Sender name', description: "Sender's name: 'From' field", key: 'from_name' },
        { label: 'Recipient', description: "One or more comma-separated recipient emails: 'To' field", key: 'to' },
        { label: 'Original recipient', description: "Typically your @in.parsetrue.com email address", key: 'original_recipient' },
        { label: 'CC', description: "One or more comma-separated recipient emails: 'CC' field", key: 'cc' },
        { label: 'BCC', description: "One or more comma-separated recipient emails: 'BCC' field", key: 'bcc' },
        { label: 'Reply to', description: "One or more comma-separated emails: 'Reply To' field", key: 'reply_to' },
        { label: 'Subject', description: 'Email subject', key: 'subject' },
        { label: 'Name', description: 'Document name', key: 'name' },
        { label: 'Filename', description: 'Document filename', key: 'filename' },
        { label: 'Received date and time', description: 'Received timestamp (date and time in ISO format)', key: 'received_at_datetime' },
        { label: 'Received date', description: 'Received timestamp (date only: YYYY-MM-DD)', key: 'received_at_date' },
        { label: 'Received time', description: 'Received timestamp (time only: hh:mm:ss)', key: 'received_at_time' },
        { label: 'Document content (original)', description: 'Document original content (html or txt)', key: 'content' },
        { label: 'Document content (plain text)', description: 'Emails and HTML documents converted into plain text (links removed)', key: 'content_plaintext' },
        { label: 'Document content (plain text with URLs)', description: 'Emails and HTML documents converted into plain text (links preserved)', key: 'content_plaintext_md' },
        { label: 'Content type', description: 'Original document media type (MIME type)', key: 'content_type' },
    ];

    const [selectedFields, setSelectedFields] = useState<string[]>([]);

    useEffect(() => {
        if (additionalFields) {
            const selected = fields
                .filter(field => additionalFields[field.key])
                .map(field => field.label);
            setSelectedFields(selected);
        }
    }, [additionalFields]);

    const toggleField = (label: string) => {
        setSelectedFields(prevSelectedFields =>
            prevSelectedFields.includes(label)
                ? prevSelectedFields.filter(field => field !== label)
                : [...prevSelectedFields, label]
        );
    };

    const handleSave = async () => {
        const metaSettings = fields.reduce((acc, field) => {
            acc[field.key] = selectedFields.includes(field.label);
            return acc;
        }, {} as { [key: string]: boolean });

        try {
            await axios.put(`${process.env.REACT_APP_BE_API_ENDPOINT}/api/inbox`, {
                inbox_id: inboxId,
                meta_settings: metaSettings,
            },
            {
                withCredentials: true,
            });
            alert('Meta settings updated successfully');
        } catch (error) {
            console.error('Failed to update meta settings', error);
            alert('Failed to update meta settings');
        }
    };

    return (
        <div className="p-4 max-w-3xl mx-0"> {/* Removed border, shadow, and aligned left */}
            <p className="mb-6 text-gray-600">
                Select the additional fields to extract key information from your emails and documents. These fields will enhance the parsed data with more contextual information.
            </p>
            <div className="space-y-4">
                {fields.map((field) => (
                    <div key={field.key} className="flex items-start">
                        <input
                            type="checkbox"
                            checked={selectedFields.includes(field.label)}
                            onChange={() => toggleField(field.label)}
                            className="checkbox text-gray-700 mr-4"
                        />
                        <div className="flex-1">
                            <span className="font-semibold block">{field.label}</span>
                            <span className="text-gray-600 text-sm">{field.description}</span>
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={handleSave} className="btn bg-gray-700 text-white hover:bg-gray-800 mt-4">
                Save
            </button>
        </div>
    );
};

export default AdditionalFieldsTab;
