// AppLayout.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';

interface AppLayoutProps {
    children: React.ReactNode;
    handleUpdateSidebarParam: (name: string, emailAlias: string) => void;
    sidebarName: string;
    sidebarEmailAlias: string;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children, sidebarName, sidebarEmailAlias }) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resendCountdown, setResendCountdown] = useState<number | null>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const { user, isAuthenticated, logout } = useAuth();

    const onLogout = async () => {
        await logout();
        navigate('/signin');
    };

    const apiEndpoint = process.env.REACT_APP_BE_API_ENDPOINT;

    const handleResendVerification = async () => {
        try {
            await axios.post(`${apiEndpoint}/api/confirm/send`, {}, { withCredentials: true });
            setResendCountdown(300); // Set countdown to 5 minutes (300 seconds)
        } catch (error) {
            console.error('Error resending verification email:', error);
        }
    };

    useEffect(() => {
        // Retrieve the target end time from local storage
        const storedEndTime = localStorage.getItem('resendEndTime');
        if (storedEndTime) {
            const endTime = new Date(storedEndTime).getTime();
            const currentTime = new Date().getTime();
            const remainingTime = Math.max(0, Math.floor((endTime - currentTime) / 1000));
            setResendCountdown(remainingTime);
        }
    }, []);

    useEffect(() => {
        if (resendCountdown !== null) {
            if (resendCountdown === 0) {
                localStorage.removeItem('resendEndTime');
                return;
            }

            const endTime = new Date().getTime() + resendCountdown * 1000;
            localStorage.setItem('resendEndTime', new Date(endTime).toISOString());

            const timer = setInterval(() => {
                setResendCountdown((prevCountdown) => {
                    if (prevCountdown === null || prevCountdown <= 1) {
                        clearInterval(timer);
                        localStorage.removeItem('resendEndTime');
                        return null;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [resendCountdown]);

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const handleStart = () => setIsLoading(true);
        const handleComplete = () => setIsLoading(false);

        // Listen for navigation events
        handleStart();
        handleComplete();

        return () => {
        };
    }, [location]);

    useEffect(() => {
        if (isAuthenticated && user && user?.email_confirmed) {
            const fetchNotifications = async () => {
                try {
                    await axios.get(`${apiEndpoint}/api/notifications`, { withCredentials: true });
                } catch (error) {
                    console.error('Error fetching notifications:', error);
                }
            };
            fetchNotifications();
        }
        else {
            console.log('User not authenticated or email not confirmed');
        }
    }, [isAuthenticated, user?.email_confirmed, apiEndpoint]);

    if (!isAuthenticated || !user) {
        // Show loading or redirect, depending on your app flow
        navigate('/signin');
    }

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-primary h-8 w-8 mb-4 animate-spin"></div>
                <p className="text-center text-gray-500 mb-6 ml-4">Loading...</p>
            </div>
        );
    }

    if (!user.email_confirmed) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <div className="card bg-white shadow-lg rounded-lg p-6 w-full max-w-md mx-auto md:min-h-[640px] flex flex-col justify-center">
                    <h3 className="text-center text-2xl font-semibold mb-4 text-gray-900">
                        Please confirm your email
                    </h3>
                    <p className="text-center text-gray-500 mb-6">
                        Please confirm your email by clicking on the confirmation link sent to {user.email}. Did not get it? Please check your spam folder or click the button below to send again.
                    </p>
                    <button
                        onClick={handleResendVerification}
                        className="bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-200 w-full mb-4"
                        disabled={resendCountdown !== null}
                    >
                        {resendCountdown !== null ? (
                            `Resend After ${Math.floor(resendCountdown / 60)} minutes ${resendCountdown % 60} seconds`
                        ) : (
                            'Send Verification Email'
                        )}
                    </button>
                    <p className="text-center text-gray-500">
                        Wrong account?{' '}
                        <button
                            onClick={onLogout}
                            className="text-primary hover:text-primary-light"
                        >
                            Log out
                        </button>
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar */}
            <Sidebar
                isCollapsed={isSidebarCollapsed}
                toggleCollapse={toggleSidebar}
                name={sidebarName}
                emailAlias={sidebarEmailAlias}
            />

            {/* Main Content Area */}
            <div className="flex flex-col flex-1 min-w-0">
                {/* Navbar */}
                <Navbar
                    isMobileMenuOpen={isMobileMenuOpen}
                    toggleMobileMenu={toggleMobileMenu}
                />

                {/* Content */}
                <div className="flex-1 overflow-auto bg-gray-50 px-6 pt-4 min-w-0">
                    {children}
                </div>
            </div>
        </div>
    );
};
export default AppLayout;
