import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface AdvancedFields {
    include_urls: boolean;
    include_imgs: boolean;
    alert_settings: {
        email_on_failed_doc_every: number;
        email_on_failed_integ_every: number;
    };
}

interface AdvancedTabProps {
    advancedFields: AdvancedFields | null;
    inboxId: string;
}

const AdvancedTab: React.FC<AdvancedTabProps> = ({ advancedFields, inboxId }) => {
    const [failedDocAlert, setFailedDocAlert] = useState(12);
    const [failedWebhookAlert, setFailedWebhookAlert] = useState(6);
    const [destinationInbox, setDestinationInbox] = useState('no other inboxes');
    const [parseURLs, setParseURLs] = useState(false);
    const [parseImgSrc, setParseImgSrc] = useState(false);

    useEffect(() => {
        if (advancedFields) {
            setParseURLs(advancedFields.include_urls);
            setParseImgSrc(advancedFields.include_imgs);
            setFailedDocAlert(advancedFields.alert_settings.email_on_failed_doc_every);
            setFailedWebhookAlert(advancedFields.alert_settings.email_on_failed_integ_every);
        }
    }, [advancedFields]);

    const handleSaveAlertSettings = async () => {
        const updatedAlertSettings = {
            alert_settings: {
                email_on_failed_doc_every: failedDocAlert,
                email_on_failed_integ_every: failedWebhookAlert,
            },
        };

        try {
            await axios.put(`${process.env.REACT_APP_BE_API_ENDPOINT}/api/inbox/alert-settings`, {
                inbox_id: inboxId,
                ...updatedAlertSettings,
            },
            {
                withCredentials: true,
            });
            alert('Alert settings updated successfully');
        } catch (error) {
            console.error('Failed to update alert settings', error);
            alert('Failed to update alert settings');
        }
    };

    const handleSaveAdvancedSettings = async () => {
        const updatedAdvancedSettings = {
            include_urls: parseURLs,
            include_imgs: parseImgSrc,
        };

        try {
            await axios.put(`${process.env.REACT_APP_BE_API_ENDPOINT}/api/inbox/advanced-settings`, {
                inbox_id: inboxId,
                ...updatedAdvancedSettings,
            },
            {
                withCredentials: true,
            });
            alert('Advanced settings updated successfully');
        } catch (error) {
            console.error('Failed to update advanced settings', error);
            alert('Failed to update advanced settings');
        }
    };

    return (
        <div className="p-4 max-w-3xl mx-0"> 
            {/* Email Notifications */}
            <h2 className="text-2xl font-semibold mb-4">Email Notifications</h2>
            <div className="mb-6">
                <label className="block font-medium mb-2">Email alerts: Failed documents</label>
                <select
                    className="select select-bordered w-full max-w-xs mb-2"
                    value={failedDocAlert}
                    onChange={(e) => setFailedDocAlert(Number(e.target.value))}
                >
                    <option value={6}>6 hours</option>
                    <option value={12}>12 hours</option>
                    <option value={24}>24 hours</option>
                    <option value={48}>48 hours</option>
                </select>
                <p className="text-sm text-gray-600">
                    Email alerts are sent whenever ParseTrue is unable to parse an incoming email or document. The above value represents the minimum time period between two email alerts.
                </p>
            </div>
            <div className="mb-6">
                <label className="block font-medium mb-2">Email alerts: Failed webhooks & integrations</label>
                <select
                    className="select select-bordered w-full max-w-xs mb-2"
                    value={failedWebhookAlert}
                    onChange={(e) => setFailedWebhookAlert(Number(e.target.value))}
                >
                    <option value={6}>6 hours</option>
                    <option value={12}>12 hours</option>
                    <option value={24}>24 hours</option>
                </select>
                <p className="text-sm text-gray-600">
                    Email alerts are sent whenever ParseTrue is unable to export data to a webhook or integration. The above value represents the minimum time period between two email alerts.
                </p>
            </div>
            <button onClick={handleSaveAlertSettings} className="btn bg-gray-700 text-white hover:bg-gray-800 mb-6">Save</button>

            {/* Parse Settings */}
            <h2 className="text-2xl font-semibold mb-4">Parse Settings</h2>
            <p className="text-sm text-gray-600 mb-4">
                Make sure you understand before changing anything here. Changes might cause unexpected results. Contact us for assistance if uncertain.
            </p>
            <div className="mb-6">
                <label className="cursor-pointer flex items-center mb-2">
                    <input
                        type="checkbox"
                        className="checkbox text-gray-700 mr-2"
                        checked={parseURLs}
                        onChange={() => setParseURLs(!parseURLs)}
                    />
                    <span className="label-text font-semibold">Parse URLs</span>
                </label>
                <label className="cursor-pointer flex items-center">
                    <input
                        type="checkbox"
                        className="checkbox text-gray-700 mr-2"
                        checked={parseImgSrc}
                        onChange={() => setParseImgSrc(!parseImgSrc)}
                    />
                    <span className="label-text font-semibold">Parse 'img src' attributes</span>
                </label>
            </div>
            <button onClick={handleSaveAdvancedSettings} className="btn bg-gray-700 text-white hover:bg-gray-800 mb-6">Save</button>

            {/* Clone the Extraction Schema */}
            <h2 className="text-2xl font-semibold mb-4">Clone the Extraction Schema</h2>
            <div className="mb-6">
                <label className="block font-medium mb-2">Destination inbox</label>
                <select
                    className="select select-bordered w-full max-w-xs mb-2"
                    value={destinationInbox}
                    onChange={(e) => setDestinationInbox(e.target.value)}
                >
                    <option>no other inboxes</option>
                    {/* Add other inbox options here */}
                </select>
                <p className="text-sm text-gray-600">
                    You can easily clone the extraction schema from this inbox (6 fields in the extraction schema, last update: 21 days ago) to a destination inbox. Cloning the schema will overwrite the existing schema in the destination inbox. This action is irreversible, so please proceed with caution.
                </p>
                <button className="btn btn-outline btn-gray-700 mt-4">Clone</button>
            </div>

            {/* Danger Zone */}
            <h2 className="text-2xl font-semibold text-red-600 mb-4">Danger Zone</h2>
            <p className="text-sm text-gray-600 mb-4">
                Once you delete the inbox, all documents and parsed data will be lost. If you have email auto-forwarding enabled, turn it off before deleting the inbox!
            </p>
            <button className="btn bg-red-600 text-white hover:bg-red-700">Delete Inbox</button>
        </div>
    );
};

export default AdvancedTab;
