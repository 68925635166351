import React, { useState, useEffect } from 'react';
import { FaEdit, FaSave } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../context/AuthContext';
import Alert from '../../../../../components/Alert/Alert';
import axios from 'axios';

const GeneralTab: React.FC = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const apiEndpoint = process.env.REACT_APP_BE_API_ENDPOINT;

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState<'success' | 'error' | 'warning' | 'info'>('info');

    // Edit first name
    const [isEditingFirstName, setIsEditingFirstName] = useState(false);
    const [firstName, setFirstName] = useState(user?.firstname || '');
    const [tempFirstName, setTempFirstName] = useState(firstName);

    // Edit last name
    const [isEditingLastName, setIsEditingLastName] = useState(false);
    const [lastName, setLastName] = useState(user?.lastname || '');
    const [tempLastName, setTempLastName] = useState(lastName);

    // Edit email
    const [isEditingEmail, setIsEditingEmail] = useState(false);
    const [email, setEmail] = useState(user?.email || '');
    const [tempEmail, setTempEmail] = useState(email);

    // Modal state
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLogout = async () => {
        await logout();
        navigate('/signin');
    };

    // Handle password changes
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        // Add logic here to handle password update
        if (newPassword === confirmPassword) {
            try {
                // Assume an API call to update the password
                await axios.post(`${apiEndpoint}/api/user/changepassword`, { newPassword }, { withCredentials: true });
                setAlertMessage('Password updated successfully!');
                setAlertType('success');
            } catch (error) {
                setAlertMessage('Error updating password!');
                setAlertType('error');
            }
        } else {
            setAlertMessage('Passwords do not match!');
            setAlertType('error');
        }
        setAlertVisible(true);
    };

    // Handle first name changes
    const handleFirstNameEdit = () => {
        setIsEditingFirstName(true);
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempFirstName(event.target.value);
    };

    const handleFirstNameSave = async () => {
        console.log('Name updated to:', tempFirstName);
        try {
            await axios.post(
                `${apiEndpoint}/api/user/profile`, 
                {
                    firstname: tempFirstName,
                },
                {
                    withCredentials: true,
                }
            );
            console.log('First name updated to:', tempFirstName);
            setFirstName(tempFirstName);
            setIsEditingFirstName(false);
        } catch (error) {
            console.error('Error updating first name:', error);
        }
    };

    const handleFirstNameCancel = () => {
        setTempLastName(firstName);
        setIsEditingFirstName(false);
    };

    // Handle last name changes
    const handleLastNameEdit = () => {
        setIsEditingLastName(true);
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempLastName(event.target.value);
    };

    const handleLastNameSave = async () => {
        console.log('Last name updated to:', tempLastName);
        try {
            await axios.post(
                `${apiEndpoint}/api/user/profile`, 
                {
                    lastname: tempLastName,
                },
                {
                    withCredentials: true,
                }                
            );
            setLastName(tempLastName);
            setIsEditingLastName(false);
        } catch (error) {
            console.error('Error updating last name:', error);
        }
    };

    const handleLastNameCancel = () => {
        setTempLastName(lastName);
        setIsEditingLastName(false);
    };

    // Handle email changes
    const handleEmailEdit = () => {
        setIsEditingEmail(true);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempEmail(event.target.value);
    };

    const handleEmailSave = async () => {
        setIsModalOpen(true);
    };

    const handleEmailSaveConfirm = async () => {
        console.log('Email updated to:', tempEmail);
        try {
            await axios.post(
                `${apiEndpoint}/api/user/profile`, 
                {
                    email: tempEmail,
                },
                {
                    withCredentials: true,
                }
            );
            setEmail(tempEmail);
            setIsEditingEmail(false);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error updating email:', error);
        }
    };

    const handleEmailCancel = () => {
        setTempEmail(email);
        setIsEditingEmail(false);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    // Preload user data when component mounts
    useEffect(() => {
        if (user) {
            setFirstName(user.firstname);
            setTempFirstName(user.firstname);
            setLastName(user.lastname);
            setTempLastName(user.lastname);
            setEmail(user.email);
            setTempEmail(user.email);
        }
    }, [user]);

    return (
        <>
            {/* Information Section */}
            <div className="p-6 bg-base-100 rounded-md shadow-md mb-6">
                <h3 className="text-2xl font-semibold mb-4">Information</h3>
                {/* Table-like container inside the card */}
                <div className="overflow-hidden rounded-md border border-gray-200 w-full mx-auto">
                    {/* First Name Field */}
                    <div className="flex items-center px-4 py-3 border-b border-gray-200 last:border-b-0">
                        {/* Label */}
                        <div className="w-32 flex-shrink-0 mr-12">
                            <p className="text-gray-700 font-bold">First Name</p>
                        </div>
                        {/* Value/Input */}
                        <div className="flex-1">
                            {isEditingFirstName ? (
                                <div className="flex items-center">
                                    <input
                                        type="text"
                                        placeholder="Enter your first name"
                                        className="input input-bordered w-full"
                                        value={tempFirstName}
                                        onChange={handleFirstNameChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleFirstNameSave();
                                        }}
                                        autoFocus
                                        maxLength={50}
                                    />
                                </div>
                            ) : (
                                <span>{firstName}</span>
                            )}
                        </div>
                        {/* Edit/Save button */}
                        <div className="flex-shrink-0 flex items-center space-x-2 ml-4">
                            {isEditingFirstName ? (
                                <>
                                    <button className="btn bg-gray-700 hover:bg-gray-800 text-white btn-sm flex items-center" onClick={handleFirstNameSave}>
                                        <FaSave className="mr-1" /> Save
                                    </button>
                                    <button className="btn bg-gray-500 hover:bg-gray-600 text-white btn-sm" onClick={handleFirstNameCancel}>
                                        Cancel
                                    </button>
                                </>
                            ) : (
                                <FaEdit className="text-gray-500 cursor-pointer" onClick={handleFirstNameEdit} />
                            )}
                        </div>
                    </div>

                    {/* Last Name Field */}
                    <div className="flex items-center px-4 py-3 border-b border-gray-200 last:border-b-0">
                        {/* Label */}
                        <div className="w-32 flex-shrink-0 mr-12">
                            <p className="text-gray-700 font-bold">Last Name</p>
                        </div>
                        {/* Value/Input */}
                        <div className="flex-1">
                            {isEditingLastName ? (
                                    <input
                                        type="text"
                                        placeholder="Enter your last name"
                                        className="input input-bordered w-full"
                                        value={tempLastName}
                                        onChange={handleLastNameChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleLastNameSave();
                                        }}
                                        autoFocus
                                        maxLength={50}
                                    />
                            ) : (
                                <span>{lastName}</span>
                            )}
                        </div>
                        {/* Edit/Save button */}
                        <div className="flex-shrink-0 flex items-center space-x-2 ml-4">
                            {isEditingLastName ? (
                                <>
                                <button className="btn bg-gray-700 hover:bg-gray-800 text-white btn-sm flex items-center" onClick={handleLastNameSave}>
                                    <FaSave className="mr-1" /> Save
                                </button>
                                <button className="btn bg-gray-500 hover:bg-gray-600 text-white btn-sm" onClick={handleLastNameCancel}>
                                    Cancel
                                </button>
                                </>
                            ) : (
                                <FaEdit className="text-gray-500 cursor-pointer" onClick={handleLastNameEdit} />
                            )}
                        </div>
                    </div>

                    {/* Email Field */}
                    <div className="flex items-center px-4 py-3 border-b border-gray-200 last:border-b-0">
                        {/* Label */}
                        <div className="w-32 flex-shrink-0 mr-12">
                            <p className="text-gray-700 font-bold">Email</p>
                        </div>
                        {/* Value/Input */}
                        <div className="flex-1">
                            {isEditingEmail ? (
                                <div className="flex items-center">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        className="input input-bordered w-full"
                                        value={tempEmail}
                                        onChange={handleEmailChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleEmailSave();
                                        }}
                                        autoFocus
                                    />
                                </div>
                            ) : (
                                <span>{email}</span>
                            )}
                        </div>
                        {/* Edit/Save button */}
                        <div className="flex-shrink-0 flex items-center space-x-2 ml-4">
                            {isEditingEmail ? (
                                <>
                                <button className="btn bg-gray-700 hover:bg-gray-800 text-white btn-sm flex items-center" onClick={handleEmailSave}>
                                    <FaSave className="mr-1" /> Save
                                </button>
                                <button className="btn bg-gray-500 hover:bg-gray-600 text-white btn-sm" onClick={handleEmailCancel}>
                                    Cancel
                                </button>
                                </>
                            ) : (
                                <FaEdit className="text-gray-500 cursor-pointer" onClick={handleEmailEdit} />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-black bg-opacity-50 absolute inset-0"></div>
                    <div className="bg-white p-6 rounded-md shadow-md z-10">
                        <h3 className="text-xl font-semibold mb-4">Email Change Confirmation</h3>
                        <p className="mb-4">
                            Changing your email will mark it as unverified. You will need to verify your email again before continuing to use your account.
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button className="btn btn-outline" onClick={handleModalClose}>
                                Cancel
                            </button>
                            <button className="btn btn-primary" onClick={handleEmailSaveConfirm}>
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Update Password Section */}
            <div className="p-6 bg-base-100 rounded-md shadow-md mb-6">
                <h3 className="text-2xl font-semibold mb-4">Update Password</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-gray-700">New password</label>
                        <input
                            type="password"
                            placeholder="New password"
                            className="input input-bordered w-full max-w-md"
                            value={newPassword}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700">Confirm new password</label>
                        <input
                            type="password"
                            placeholder="Confirm new password"
                            className="input input-bordered w-full max-w-md"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                    </div>
                    <button type="submit" className="btn bg-gray-700 hover:bg-gray-800 text-white">
                        Update Password
                    </button>
                </form>
                {alertVisible && (
                    <div className="mt-4 max-w-xs">
                    <Alert
                        message={alertMessage}
                        onClose={() => setAlertVisible(false)}
                        type={alertType}
                    />
                    </div>
                )}
            </div>

            {/* Security Section */}
            <div className="p-6 bg-base-100 rounded-md shadow-md mb-6">
                <h3 className="text-2xl font-semibold mb-4">Security</h3>
                <div>
                    <h4 className="text-lg font-semibold mb-2">Protect your account</h4>
                    <p className="text-gray-600 mb-4">
                        You'll be signed out of all devices, including the one you’re using now.
                    </p>
                    <button className="btn btn-outline" onClick={handleLogout}>
                        Sign out of all devices
                    </button>
                </div>
            </div>

            {/* Danger Zone Section */}
            {/* TODO: Implement this */}
            <div className="p-6 bg-base-100 rounded-md shadow-md mb-6">
                <h3 className="text-2xl font-semibold mb-4 text-red-600">
                    Danger Zone
                </h3>
                <div>
                    <h4 className="text-lg font-semibold mb-2">Delete your account</h4>
                    <p className="text-gray-600 mb-4">
                        Do you want to downgrade instead?{' '}
                        <a href="#" className="text-blue-600">
                            Manage subscriptions.
                        </a>
                    </p>
                    <button className="btn bg-red-600 text-white hover:bg-red-700">Delete account</button>
                </div>
            </div>

            {/* Help Section */}
            <div className="text-center">
                <p className="text-gray-600">Need help? Let's get in touch.</p>
            </div>
        </>
    );
};

export default GeneralTab;
