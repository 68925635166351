import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const apiEndpoint = process.env.REACT_APP_BE_API_ENDPOINT;

const VerifyEmailConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [message, setMessage] = useState('');
    const [countdown, setCountdown] = useState(3);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (!token) {
            setMessage('Missing or invalid token or code. Please try again.');
            setIsSuccess(false);
            setIsLoading(false);
            return;
        }

        if (token) {
            axios.get(`${apiEndpoint}/api/auth/confirm/email?token=${token}`)
                .then(response => {
                    setMessage('Your email has been verified, please login using this email.');
                    setIsSuccess(true);
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message || 'An error occurred. Please try again later.';
                    if (error.response?.status === 409) {
                        setMessage('Email already confirmed. Redirecting to sign in page...');
                        setIsSuccess(true);
                    } 
                    else if (errorMessage.includes('Invalid confirmation code') || errorMessage.includes('Confirmation code expired')) {
                        setMessage(`${errorMessage}. Please resend the verification email using the link below.`);
                        setIsSuccess(false);
                    } else {
                        setMessage('An error occurred. Please try again later or resend the verification email using the link below.');
                        setIsSuccess(false);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setMessage('Missing or invalid token. Please try again.');
            setIsSuccess(false);
            setIsLoading(false);
        }
    }, [location.search]);

    useEffect(() => {
        if (isSuccess) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        navigate('/signin');
                        clearInterval(timer);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isSuccess, navigate]);

    const handleResendVerification = () => {
        navigate('/resend-verification');
    };

    const handleSignIn = () => {
        navigate('/signin');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
            <div className="card bg-white shadow-lg rounded-lg p-6 w-full max-w-md mx-auto md:min-h-[640px] flex flex-col justify-center">
                <h3 className="text-center text-2xl font-semibold mb-4 text-gray-900">
                    Email Verification
                </h3>
                {isLoading ? (
                    <div className="flex flex-col items-center">
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-primary h-12 w-12 mb-4"></div>
                        <p className="text-center text-gray-500 mb-6">Loading...</p>
                    </div>
                ) : (
                    <>
                        <p className="text-center text-gray-500 mb-6">
                            {message}
                        </p>
                        {isSuccess ? (
                            <>
                                <p className="text-center text-gray-500 mb-6">
                                    Redirecting to sign in page in {countdown} seconds...
                                </p>
                                <button onClick={handleSignIn} className="w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-200">
                                    Go to Sign In
                                </button>
                            </>
                        ) : (
                            <button onClick={handleResendVerification} className="w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-200">
                                Send Verification Email
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default VerifyEmailConfirmation;