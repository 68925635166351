import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { Link, useLocation } from 'react-router-dom';
import { FaTrashAlt, FaTimesCircle, FaFileExport } from 'react-icons/fa';

import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';

const ParsedData: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [state, setState] = useState({
        selectedRowData: null,
        showSlider: false,
        showModal: false,
    });

    // Test data: a list of objects with varying key-value pairs
    const dataList = [
        {
            "bill_to_address": "John Doe 456 Elm Street, Los Angeles, CA 90001",
            "name": "John Doe",
            "order": [
                {
                    "description": "Men's Running Shoes, Size 10, Black",
                    "item_name": "Running Shoes",
                    "pricing": "100.00",
                    "quantity": "1",
                    "total_price": "100.00"
                },
                {
                    "description": "Estimated delivery in 5-7 business days",
                    "item_name": "Shipping",
                    "pricing": "5.00",
                    "quantity": "1",
                    "total_price": "5.00"
                }
            ],
            "order_amt": "113.40",
            "shipping_address": "John Doe 456 Elm Street, Los Angeles, CA 90001",
            "tax": "8.40"
        },
        {
            "name": "Jane Smith",
            "order": [
                {
                    "description": "Women's Running Shoes, Size 8, White",
                    "item_name": "Running Shoes",
                    "pricing": "120.00",
                    "quantity": "1",
                    "total_price": "120.00"
                }
            ],
            "order_amt": "120.00",
            "shipping_address": "Jane Smith 123 Maple Street, New York, NY 10001",
            "discount": "10.00"
        }
    ];


    // Function to extract all unique headers from a list of objects
    const extractHeaders = (dataList: any[]): string[] => {
        const headers = new Set<string>();

        const extractHeadersFromObject = (obj: any, prefix = '') => {
            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach((item: any, index: number) => {
                        extractHeadersFromObject(item, `${prefix}${key}.${index}.`);
                    });
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    extractHeadersFromObject(obj[key], `${prefix}${key}.`);
                } else {
                    headers.add(`${prefix}${key}`);
                }
            }
        };

        dataList.forEach(obj => extractHeadersFromObject(obj));
        return Array.from(headers);
    };

    // Function to extract row data based on headers
    const extractRowData = (obj: any, headers: string[]): string[] => {
        return headers.map(header => {
            const keys = header.split('.');
            let value = obj;
            for (let key of keys) {
                if (value && key in value) {
                    value = value[key];
                } else {
                    value = ''; // Represent missing values as blank
                    break;
                }
            }
            return String(value);
        });
    };

    const headers = extractHeaders(dataList);
    const rows = dataList.map(obj => extractRowData(obj, headers));

    const handleRowClick = (rowData: any) => {
        setState(prev => ({ ...prev, selectedRowData: rowData, showSlider: true }));
    };

    const handleCloseSlider = () => {
        setState(prev => ({ ...prev, showSlider: false }));
    };

    const handleDelete = () => {
        setState(prev => ({ ...prev, showModal: true }));
    };

    const confirmDelete = () => {
        // Handle deletion logic
        setState({ selectedRowData: null, showSlider: false, showModal: false });
    };

    return (
        <>
            {/* Breadcrumb */}
            <Breadcrumb pageName='Parsed Data' />

            {/* Header */}
            <div className="flex justify-end items-center mb-4">
                <button className="btn bg-gray-700 text-white hover:bg-gray-800">
                    <FaFileExport /> Export
                </button>
            </div>

            {/* Table */}
            <div className="overflow-x-auto">
                <table className="table w-full">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index} className="px-4 py-2 whitespace-nowrap text-white bg-gray-700">{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                onClick={() => handleRowClick(dataList[rowIndex])}
                                className="hover:bg-gray-100 cursor-pointer even:bg-gray-50"
                            >
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} className="px-4 py-2 whitespace-nowrap">{cell}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="mt-5 text-sm text-gray-600 text-right">
                <span>Showing 1 to {dataList.length} of {dataList.length} documents</span>
            </div>

            {/* Slider for JSON display */}
            {state.showSlider && (
                <div className="fixed top-0 right-0 w-full md:w-1/3 h-full bg-white shadow-lg z-50 transform transition-transform duration-300">
                    <div className="p-4 flex flex-col h-full">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-bold">Parsed</h3>
                            <div className="flex space-x-2">
                                <button className="btn btn-outline btn-error btn-sm" onClick={handleDelete}>
                                    <FaTrashAlt />
                                </button>
                                <button className="btn btn-outline btn-sm" onClick={handleCloseSlider}>
                                    <FaTimesCircle />
                                </button>
                            </div>
                        </div>
                        <div className="flex-grow overflow-auto">
                            <ReactJson
                                src={state.selectedRowData || {}}
                                collapsed={1}
                                enableClipboard={true}
                                displayDataTypes={false}
                                displayObjectSize={true}
                                theme="monokai"
                                name={false}
                            />
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for delete confirmation */}
            {state.showModal && (
                <div className="modal modal-open">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Confirm Delete</h3>
                        <p className="py-4">Are you sure you want to delete this data?</p>
                        <div className="modal-action">
                            <button
                                className="btn"
                                onClick={() => setState(prev => ({ ...prev, showModal: false }))}
                            >
                                Cancel
                            </button>
                            <button className="btn btn-error" onClick={confirmDelete}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ParsedData;
