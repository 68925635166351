import React, { useState } from 'react';
import { FaBars, FaUserCircle, FaBell, FaQuestionCircle } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NavItem from '../NavItem/NavItem';
import { useAuth } from '../../context/AuthContext';
import { navigationLinks } from '../NavItem/navigationLinks';

interface NavbarProps {
    isMobileMenuOpen: boolean;
    toggleMobileMenu: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ isMobileMenuOpen, toggleMobileMenu }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const location = useLocation();

    const [notifications] = useState([
        { title: 'New Message', message: 'You have a new message', lastUpdated: new Date() },
        { title: 'New Message 2', message: 'You have a newer message', lastUpdated: new Date() },
        // Add more notifications here
    ]);

    const onLogout = async () => {
        await logout();
        navigate('/signin');
    };

    const filteredLinks = navigationLinks.filter((link) => {
        if (location.pathname.includes('inbox')) {
            return link.context === 'inbox';
        } else {
            return link.context === 'default';
        }
    });

    return (
        <div className="navbar bg-gray-800 text-white relative">
            {/* Left Side */}
            <div className="flex-none">
                {/* Burger Menu Icon on Mobile */}
                <button
                    className="btn btn-ghost btn-circle md:hidden text-white hover:bg-primary-light transition duration-200"
                    onClick={toggleMobileMenu}
                >
                    <FaBars className="text-xl" />
                </button>
            </div>

            {/* Center */}
            <div className="flex-1">
                {/* PT Logo centered on mobile */}
                <Link
                    to="/"
                    className="btn btn-ghost normal-case text-xl mx-auto md:mx-0 md:hidden text-white hover:text-primary-light"
                >
                    {/* Show "PT" on mobile */}
                    <span className="md:hidden">PT</span>
                </Link>
            </div>

            {/* Right Side */}
            <div className="flex-none">
                <div className="flex space-x-4 items-center">
                    {/* Icons displayed only on medium and larger screens */}
                    <div className="hidden md:flex space-x-4">
                        {/* Notifications */}
                        <div className="dropdown dropdown-end">
                            <label tabIndex={0} className="btn btn-ghost btn-circle text-white hover:bg-gray-700 transition duration-200 relative">
                                <FaBell className="text-xl" />
                                {notifications.length > 0 && (
                                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                                        {notifications.length}
                                    </span>
                                )}
                            </label>
                            <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 shadow bg-gray-700 text-white rounded-box w-52 z-20"
                            >
                                {notifications.map((notification, index) => (
                                    <li key={index} className="-2 border-b border-gray-700 last:border-b-0">
                                        <a className="block hover:bg-gray-600 transition duration-200">
                                            <div className="font-bold">{notification.title}</div>
                                            <div className="text-sm">{notification.message}</div>
                                            <div className="text-xs text-gray-400">{notification.lastUpdated.toLocaleString()}</div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* Help Center */}
                        <div className="dropdown dropdown-end">
                            <label tabIndex={0} className="btn btn-ghost btn-circle text-white hover:bg-gray-700 transition duration-200">
                                <FaQuestionCircle className="text-xl" />
                            </label>
                            <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 shadow bg-gray-700 text-white rounded w-52"
                            >
                                <li className="-2 border-b border-gray-700 last:border-b-0">
                                    <Link to="/help" className="block hover:text-white hover:bg-gray-600 transition duration-200">
                                        Help Center
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* User Icon with Profile/Logout Dropdown */}
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-circle text-white hover:bg-gray-700 transition duration-200">
                            <FaUserCircle className="text-xl" />
                        </label>
                        <ul
                            tabIndex={0}
                            className="dropdown-content menu p-2 shadow bg-gray-700 text-white rounded w-52"
                        >
                            <li className="-2 border-b border-gray-700 last:border-b-0">
                                <a className="block hover:text-white hover:bg-gray-600 transition duration-200">
                                    Profile
                                </a>
                            </li>
                            <li className="-2 border-b border-gray-700 last:border-b-0">
                                <button onClick={onLogout} className="block hover:text-white hover:bg-gray-600 transition duration-200">
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Mobile Menu Dropdown */}
            {isMobileMenuOpen && (
                <div className="absolute top-full left-0 w-1/2 bg-primary-light md:hidden z-10 rounded-br-lg">
                    <ul className="menu p-2 rounded w-full space-y-2">
                        {filteredLinks
                            .filter((link) => link.showInMobileMenu)
                            .reduce((acc, link, index, array) => {
                                if (index > 0 && link.context !== array[index - 1].context) {
                                    acc.push(
                                        <li key={`divider-${index}`} className="divider">
                                            <hr className="border-t border-gray-300" />
                                        </li>
                                    );
                                }
                                acc.push(
                                    <NavItem
                                        key={link.to}
                                        to={link.to}
                                        label={link.label}
                                        icon={link.icon}
                                        onClick={toggleMobileMenu}
                                        className={`hover:bg-primary-dark text-white transition duration-200 ${link.customClass || ''}`}
                                    />
                                );
                                return acc;
                            }, [] as JSX.Element[])}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Navbar;

