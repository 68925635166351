import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import Alert from '../../components/Alert/Alert';
import LeftColumn from '../../components/Col/LeftColumn/LeftColumn';

const ResetPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [countdown, setCountdown] = useState(5);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const apiEndpoint = process.env.REACT_APP_BE_API_ENDPOINT;

    useEffect(() => {
        if (!token) {
            navigate('/signin');
        }
    }, [token, navigate]);

    useEffect(() => {
        if (success) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        navigate('/signin');
                        clearInterval(timer);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [success, navigate]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!token) {
            setError('Invalid or missing token/code.');
            return;
        }

        if (newPassword.length < 8) {
            setError('Password must be at least 8 characters.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await axios.post(`${apiEndpoint}/api/auth/resetpassword`, {
                token,
                new_password: newPassword,
            });

            if (response.status === 200) {
                setSuccess('Password has been changed. Please sign in again!');
                setError(null);
                setTimeout(() => {
                    navigate('/signin');
                }, 2000); // Redirect after 2 seconds
            } else {
                setError('Something went wrong. Please try again.');
                setSuccess(null);
            }
        } catch (error) {
            setError('Something went wrong. Please try again.');
            setSuccess(null);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (success) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <div className="card bg-white shadow-lg rounded-lg p-6 w-full max-w-md mx-auto md:min-h-[640px] flex flex-col justify-center">
                    <h3 className="text-center text-2xl font-semibold mb-4 text-gray-900">
                        Password Changed Successfully
                    </h3>
                    <p className="text-center text-gray-500 mb-6">
                        Go back to login or be redirected in {countdown} seconds.
                    </p>
                    <button
                        onClick={() => navigate('/signin')}
                        className="w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-200"
                    >
                        Go to Login
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
            <div className="flex flex-col md:flex-row w-full max-w-5xl shadow-lg rounded-lg md:min-h-[640px]">
                {/* Left Column */}
                <LeftColumn />

                {/* Right Column */}
                <div className="bg-white p-6 md:w-1/2 w-full rounded-r-lg flex items-center justify-center">
                    <div className="max-w-md mx-auto">
                        <h2 className="text-center text-xl font-semibold mb-4 text-gray-900">
                            Setup New Password
                        </h2>
                        <p className="text-center text-gray-500 mb-6">
                            Have you already reset the password?{' '}
                            <a href="#signin" className="text-primary hover:text-primary-light">
                                Sign in
                            </a>
                        </p>
                        {error && (
                            <Alert
                                message={error}
                                onClose={() => setError(null)}
                                type="error"
                            />
                        )}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="formPassword" className="block text-gray-700 mb-2">
                                    Password
                                </label>
                                <input
                                    id="formPassword"
                                    type="password"
                                    placeholder="Password"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                                <p className="text-gray-500 text-sm mt-1">Use 8 or more characters with a mix of letters, numbers & symbols.</p>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="formConfirmPassword" className="block text-gray-700 mb-2">Repeat Password</label>
                                <input
                                    id="formConfirmPassword"
                                    type="password"
                                    placeholder="Repeat Password"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className={`w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <span className="loading loading-spinner"></span>
                                        <span className="italic">Please wait</span>
                                    </div>
                                ) : (
                                    'Submit'
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordPage;