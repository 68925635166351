import React from 'react';
import { FaUsers } from 'react-icons/fa'; // Import an icon from react-icons

const TeamTab: React.FC = () => {
    return (
        <div className="flex justify-start items-center h-full my-8">
            <div className="text-center p-8 border border-gray-300 rounded-lg bg-white max-w-lg w-full">
                <div className="flex justify-center">
                    <FaUsers className="text-6xl text-gray-700 mb-5" />
                </div>
                <h2 className="text-2xl font-bold mb-2">Upgrade Your Subscription to Use Teams</h2>
                <p className="text-gray-600 mb-5">Share your account with other members of your team</p>
                <button className="btn btn-error text-white">Upgrade Subscription</button>
            </div>
        </div>
    );
};

export default TeamTab;
