import React, { useState, useEffect } from 'react';
import { FaTag, FaCopy } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';

interface Inbox {
    inbox_id: string;
    team_id: string;
    name: string;
    email_alias: string;
    retention: number;
    is_active: boolean;
    stats: {
        last_updated: string;
        total: number;
        parsed: number;
        failed: number;
        credits_used: number;
        failed_webhooks: number;
    };
}

const Inboxes: React.FC = () => {
    const [showAlert, setShowAlert] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [inboxesData, setInboxesData] = useState<Inbox[]>([]);

    const itemsPerPage = 10; // Items per page

    useEffect(() => {
        const fetchInboxes = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BE_API_ENDPOINT}/api/inbox`,
                    {
                        withCredentials: true,
                    }
                );
                console.log('Inboxes:', response.data.inboxes);
                setInboxesData(response.data.inboxes);
            } catch (error) {
                console.error('Error fetching inboxes:', error);
            }
        };

        fetchInboxes();
    }, []);

    // Conditionally add test data if in local environment
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'local') {
            setInboxesData([
                {
                    inbox_id: '1',
                    team_id: 'team1',
                    name: 'Inbox 1',
                    email_alias: 'inbox1',
                    retention: 30,
                    is_active: true,
                    stats: {
                        last_updated: '2023-10-01',
                        total: 100,
                        parsed: 80,
                        failed: 20,
                        credits_used: 50,
                        failed_webhooks: 5,
                    },
                },
            ]);
        }
    }, []);

    const totalItems = inboxesData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleCopy = (e: React.MouseEvent, email: string) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent the card click event
        navigator.clipboard.writeText(email);
        setShowAlert(email);
        setTimeout(() => setShowAlert(null), 2000);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentInboxes = inboxesData.slice(startIndex, endIndex);

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex mb-4 items-center justify-between">
                <h1 className="text-2xl font-bold">Inboxes</h1>
                <Link to="/dashboard/create-inbox" className="font-bold text-white bg-gray-700 rounded px-3 py-2 hover:bg-gray-800 transition duration-200">
                    + Create New Inbox
                </Link>
            </div>
            {inboxesData.length === 0 ? (
                <div className="text-center mt-10 border-2 border-dotted border-gray-300 p-4">
                    <p className="text-lg text-gray-600 mb-6">
                        There are no inboxes available. Click to create a new inbox
                    </p>
                    <div className="mb-4">
                        <Link to="/dashboard/create-inbox" className="font-bold text-white bg-primary rounded px-6 py-3 hover:bg-primary-dark transition duration-200">
                            + Create New Inbox
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="flex-wrap">
                    {currentInboxes.map((inbox) => (
                        <Link
                            key={inbox.inbox_id}
                            to={`/dashboard/inbox/${inbox.inbox_id}`}
                            className="w-full md:w-1/2 lg:w-1/3 p-2 no-underline"
                        >
                            <div className="bg-white p-4 shadow-md rounded-lg h-full flex flex-col justify-between hover:shadow-lg transition-shadow duration-300">
                                <div className="flex justify-between items-center">
                                    <div className="flex-grow">
                                        <h4 className="text-xl font-bold mb-2 text-gray-800">
                                            <Link
                                                to={`/dashboard/inbox/${inbox.inbox_id}`}
                                                className="no-underline hover:text-gray-700 transition duration-200"
                                            >
                                                {inbox.name}
                                            </Link>
                                        </h4>
                                    </div>
                                    <div className="text-right">
                                        <span>In the last 30 days</span>
                                    </div>
                                </div>

                                <div className="mt-4 overflow-x-auto">
                                    <table className="w-full border-collapse table-fixed min-w-full">
                                        <thead>
                                            <tr>
                                                <th className="p-2 border border-gray-300 bg-gray-700 text-white font-bold text-xs">Total</th>
                                                <th className="p-2 border border-gray-300 bg-gray-700 text-white font-bold text-xs">Parsed</th>
                                                <th className="p-2 border border-gray-300 bg-gray-700 text-white font-bold text-xs">Failed</th>
                                                <th className="p-2 border border-gray-300 bg-gray-700 text-white font-bold text-xs">Credits Used</th>
                                                <th className="p-2 border border-gray-300 bg-gray-700 text-white font-bold text-xs">Failed Webhooks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="p-2 border border-gray-300 text-center">
                                                    <span className="inline-block px-2 py-1 bg-blue-500 text-white rounded-full">
                                                        {inbox.stats.total}
                                                    </span>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-center">
                                                    <span className="inline-block px-2 py-1 bg-green-500 text-white rounded-full">
                                                        {inbox.stats.parsed}
                                                    </span>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-center">
                                                    <span className="inline-block px-2 py-1 bg-red-500 text-white rounded-full">
                                                        {inbox.stats.failed}
                                                    </span>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-center">
                                                    <span className="inline-block px-2 py-1 bg-yellow-500 text-white rounded-full">
                                                        {inbox.stats.credits_used}
                                                    </span>
                                                </td>
                                                <td className="p-2 border border-gray-300 text-center">
                                                    <span className="inline-block px-2 py-1 bg-teal-500 text-white rounded-full">
                                                        {inbox.stats.failed_webhooks}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="mt-4 flex">
                                    <div className="w-full max-w-xs relative">
                                        <input
                                            type="text"
                                            value={`${inbox.email_alias}@${process.env.REACT_APP_INBOX_DOMAIN}`}
                                            disabled
                                            readOnly
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                        <button
                                            onClick={(e) => handleCopy(e, `${inbox.email_alias}@${process.env.REACT_APP_INBOX_DOMAIN}`)}
                                            className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-2 text-gray-700 hover:text-gray-800 transition duration-200"
                                            aria-label="Copy Email"
                                        >
                                            <FaCopy />
                                        </button>
                                        {showAlert === inbox.email_alias && (
                                            <div className="absolute top-full right-0 mt-2 mr-2 z-50 bg-gray-700 border border-gray-600 text-white px-4 py-3 rounded shadow-lg">
                                                Content copied to clipboard!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            )}

            {/* Pagination */}
            {inboxesData.length > 0 && (
                <div className="mt-6 flex justify-between items-center">
                    <div className="text-gray-600">
                        Showing {startIndex + 1} to {Math.min(endIndex, totalItems)} of {totalItems} items
                    </div>

                    {/* Pagination Buttons */}
                    {totalPages > 1 && (
                        <div className="flex space-x-2">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`btn ${currentPage === index + 1 ? 'bg-gray-700 text-white' : 'border border-gray-700 text-gray-700'}`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Inboxes;
