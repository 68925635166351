// navigationLinks.ts

import { IconType } from 'react-icons';
import { 
    FaArrowLeft, 
    FaInbox, 
    FaHammer, 
    FaUserCog, 
    FaBell, 
    FaQuestionCircle, 
    FaCog, 
    FaFileCode, 
    FaCode, 
    FaCodeBranch } from 'react-icons/fa';

interface NavigationLink {
    to: string;
    label: string;
    icon: IconType;
    showInSidebar?: boolean;
    showInMobileMenu?: boolean;
    showInDesktopMenu?: boolean;
    context: 'default' | 'inbox';
    customClass?: string;
}

export const navigationLinks: NavigationLink[] = [
    {
        to: '/dashboard/inboxes',
        label: 'Inboxes',
        icon: FaInbox,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'default',
    },
    {
        to: '/dashboard/account',
        label: 'Account & Settings',
        icon: FaUserCog,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'default',
    },
    {
        to: `/dashboard/inbox/:id/back-to-inboxes`,
        label: 'Back to Inboxes',
        icon: FaArrowLeft,
        showInSidebar: false,
        showInMobileMenu: true,
        context: 'inbox',
        customClass: 'border border-white rounded-br-lg rounded-tl-lg', // Add custom class for special styling
    },
    {
        to: `/dashboard/inbox/:id`, // start of inbox view routes
        label: 'Inbox',
        icon: FaInbox,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
    {
        to: `/dashboard/inbox/:id/parsed-data`,
        label: 'Parsed Data',
        icon: FaFileCode,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
    {
        to: `/dashboard/inbox/:id/integrations`,
        label: 'Integrations',
        icon: FaCodeBranch,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
    {
        to: `/dashboard/inbox/:id/settings`,
        label: 'Inbox Settings',
        icon: FaCog,
        showInSidebar: true,
        showInMobileMenu: true,
        context: 'inbox',
    },
];