import React, { useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const BillingTab: React.FC = () => {
    const [value, setValue] = useState<any>(null);
    const options = countryList().getData();

    const changeHandler = (selectedOption: any) => {
        setValue(selectedOption);
    };

    return (
        <div className="p-6 bg-base-100 rounded-md shadow-md">
            <h3 className="text-2xl font-semibold mb-2">Billing Details</h3>
            <p className="text-gray-600 mb-6">
                Billing details appear on your invoices.
            </p>
            <form className="space-y-6">
                {/* Bill To */}
                <div className="flex items-center">
                    <label className="w-1/4 text-gray-700">Bill to</label>
                    <input
                        type="text"
                        placeholder="Company or account owner"
                        className="input input-bordered w-3/4"
                    />
                </div>

                {/* VAT ID */}
                <div className="flex items-center">
                    <label className="w-1/4 text-gray-700">
                        VAT ID <span className="text-sm text-gray-500">(optional)</span>
                    </label>
                    <input
                        type="text"
                        placeholder="VAT ID (optional)"
                        className="input input-bordered w-3/4"
                    />
                </div>

                {/* Street */}
                <div className="flex items-center">
                    <label className="w-1/4 text-gray-700">Street</label>
                    <input
                        type="text"
                        placeholder="Street"
                        className="input input-bordered w-3/4"
                    />
                </div>

                {/* ZIP and City */}
                <div className="flex flex-wrap -mx-2">
                    <div className="w-full md:w-1/2 px-2">
                        <label className="text-gray-700">ZIP or Postal code</label>
                        <input
                            type="text"
                            placeholder="ZIP or Postal code"
                            className="input input-bordered w-full"
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-2 mt-4 md:mt-0">
                        <label className="text-gray-700">City</label>
                        <input
                            type="text"
                            placeholder="City"
                            className="input input-bordered w-full"
                        />
                    </div>
                </div>

                {/* Country */}
                <div className="flex items-center">
                    <label className="w-1/4 text-gray-700">Country</label>
                    <div className="w-3/4">
                        <Select
                            options={options}
                            value={value}
                            onChange={changeHandler}
                            className="react-select-container"
                            classNamePrefix="react-select"
                        />
                    </div>
                </div>

                {/* Save Button */}
                <div className="flex justify-end">
                    <button type="submit" className="btn bg-gray-700 hover:bg-gray-800 text-white text-lg">
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default BillingTab;
