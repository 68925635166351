import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';

const Integrations = () => {
    const apps = [
        {
            title: 'Google',
            description: 'Connect Mailmeteor to your Google account to send emails via Gmail.',
            buttonText: 'Connect with Google',
            buttonLink: '#',
            icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg',
        },
        {
            title: 'Gmail',
            description: 'Send email campaigns and more, right into your Gmail inbox. Install the Chrome extension to get started.',
            buttonText: 'Install Mailmeteor for Gmail',
            buttonLink: '#',
            icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png',
        },
        {
            title: 'Google Sheets',
            description: 'Send emails directly from your spreadsheets by using the official Mailmeteor add-on for Google Sheets.',
            buttonText: 'Install Mailmeteor for Sheets',
            buttonLink: '#',
            icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4f/Google_Sheets_2020_Logo.svg',
        },
    ];

    return (
        <>
            {/* Breadcrumb */}
            <Breadcrumb pageName='Integrations' />

            <p className="text-center text-gray-500 mb-8">
                Get the most out of Mailmeteor by integrating with third-party applications.
            </p>

            {/* App Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {apps.map((app, index) => (
                    <div
                        key={index}
                        className="card bg-base-100 shadow-lg rounded-lg p-6 border border-gray-300 flex flex-col justify-between h-full"
                        style={{ minHeight: '360px' }}  // Setting min-height for consistent card size
                    >
                        <img src={app.icon} alt={`${app.title} icon`} className="h-16 mx-auto mb-4" />
                        <h3 className="text-xl font-bold mb-2 text-center">{app.title}</h3>

                        {/* Description with ellipsis */}
                        <p className="text-gray-600 text-center mb-4 line-clamp-3">
                            {app.description}
                        </p>

                        {/* Action Button */}
                        <a href={app.buttonLink} className="btn bg-blue-600 text-white w-full mt-auto hover:bg-blue-700">
                            {app.buttonText}
                        </a>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Integrations;
