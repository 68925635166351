import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Alert from '../../components/Alert/Alert';
import LeftColumn from '../../components/Col/LeftColumn/LeftColumn';

const ResetLoginPage: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [countdown, setCountdown] = useState(5);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (success) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        navigate('/signin');
                        clearInterval(timer);
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [success, navigate]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);
        try {
            const apiEndpoint = process.env.REACT_APP_BE_API_ENDPOINT;
            const response = await axios.post(`${apiEndpoint}/api/auth/forgotpassword`, { email });
            if (response.status === 200) {
                setSuccess('Password reset link has been sent to your email.');
                setError(null);
            } else {
                setError('Something went wrong. Please try again.');
                setSuccess(null);
            }
        } catch (error) {
            setError('Something went wrong. Please try again.');
            setSuccess(null);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (success) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <div className="card bg-white shadow-lg rounded-lg p-6 w-full max-w-md mx-auto md:min-h-[640px] flex flex-col justify-center">
                    <h3 className="text-center text-2xl font-semibold mb-4 text-gray-900">
                        Password Reset Instructions Sent
                    </h3>
                    <p className="text-center text-gray-500 mb-6">
                        Please check your email for reset instructions. You will be redirected to the login page in {countdown} seconds.
                    </p>
                    <button
                        onClick={() => navigate('/signin')}
                        className="w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-200"
                    >
                        Go to Login
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
            <div className="flex flex-col md:flex-row w-full max-w-5xl shadow-lg rounded-lg md:min-h-[640px]">
                {/* Left Column */}
                <LeftColumn />

                {/* Right Column */}
                <div className="bg-white p-6 md:w-1/2 w-full rounded-r-lg flex flex-col justify-center">
                    <div className="max-w-md mx-auto">
                        <h2 className="text-center text-2xl font-semibold mb-4 text-gray-900">
                            Forget Password
                        </h2>
                        <p className="text-center text-gray-500 mb-6">
                            Enter your email to reset your password.
                        </p>
                        {error && <Alert message={error} onClose={() => setError(null)} type="error" />}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="flex justify-start space-x-2">
                                <button
                                    type="submit"
                                    className={`bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark transition duration-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <div className="flex items-center justify-center space-x-2">
                                            <span className="loading loading-spinner"></span>
                                            <span className="italic">Please wait</span>
                                        </div>
                                    ) : (
                                        'Send Reset Instructions'
                                    )}
                                </button>
                            </div>
                        </form>

                        {/* Footer */}
                        <div className="mt-4 text-center text-sm text-gray-500">
                            <p>
                                Don't have an Account?{' '}
                                <a href="/signup" className="text-primary hover:text-primary-light">
                                    Create one here
                                </a>.
                            </p>
                            <p>
                                Forgot your password?{' '}
                                <a href="/reset" className="text-primary hover:text-primary-light">
                                    Reset it here
                                </a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetLoginPage;