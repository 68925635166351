import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

import { ReactComponent as GoogleIcon } from '../../assets/icons/google_icon.svg';
import Alert from '../../components/Alert/Alert';
import LeftColumn from '../../components/Col/LeftColumn/LeftColumn';

const apiEndpoint = process.env.REACT_APP_BE_API_ENDPOINT;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;

const SigninPage: React.FC = () => {

    const { login, isLoading } = useAuth();
    const navigate = useNavigate();

    // State for form input
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.get('error') === 'true') {
            setError('Something went wrong, please try again. If error persists, please contact us.');
        }
    }, []);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError('');

        try {
            await login(email, password);
            navigate('/dashboard');
        } catch (error) {
            const err = error as { message: string; response?: { status: number; } };
            console.log(err.message)
            if (err.response?.status === 401) {
                setError('Invalid email or password. Please try again.');
            } else if (err.response?.status === 404) {
                setError('Account not found for that email. Please register an account and try again.');
            } else {
                setError('Something went wrong. Please try again later.');
            }
        }
    };

    // Initiate Google OAuth login
    const handleGoogleLogin = () => {
        const oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${googleClientId}&redirect_uri=${redirectUri}&scope=openid%20email%20profile&state=randomStateString`;

        // Redirect the user to Google's OAuth 2.0 consent page
        window.location.href = oauthUrl;
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-50">
            <div className="flex flex-col md:flex-row w-full max-w-5xl shadow-lg rounded-lg md:min-h-[640px]">
                {/* Left Column */}
                <LeftColumn />

                {/* Right Column */}
                <div className="bg-white p-6 md:w-1/2 w-full rounded-r-lg flex flex-col justify-center">
                    <div className="max-w-md mx-auto">
                        {error && <Alert message={error} onClose={() => setError(null)} type="error" />}
                        <h2 className="text-center text-2xl font-semibold mb-4 text-gray-900">Sign In</h2>
                        <p className="text-center text-gray-500 mb-6">With social accounts</p>
                        <button
                            onClick={handleGoogleLogin}
                            className="w-full border border-gray-300 py-2 flex items-center justify-center space-x-2 rounded-md hover:bg-gray-100 mb-2 transition duration-200"
                        >
                            <GoogleIcon className="w-5 h-5" />
                            <span className="text-gray-700">Sign in with Google</span>
                        </button>
                        <div className="my-4 flex items-center">
                            <span className="border-t border-gray-300 flex-grow"></span>
                            <span className="mx-2 text-gray-500">Or sign in with</span>
                            <span className="border-t border-gray-300 flex-grow"></span>
                        </div>
                        <form className="space-y-4" onSubmit={handleLogin}>
                            <input
                                type="email"
                                placeholder="Email"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-light"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                type="submit"
                                className={`w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition duration-200 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <span className="loading loading-spinner"></span>
                                        <span className="italic">Please wait</span>
                                    </div>
                                ) : (
                                    'Sign In'
                                )}
                            </button>
                        </form>

                        {/* Footer */}
                        <div className="mt-4 text-center text-sm text-gray-500">
                            <p>
                                Don't have an Account?{' '}
                                <a href="/signup" className="text-primary hover:underline">
                                    Create one here
                                </a>.
                            </p>
                            <p>
                                Forgot your password?{' '}
                                <a href="/reset" className="text-primary hover:underline">
                                    Reset it here
                                </a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SigninPage;