import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { HiChevronRight } from 'react-icons/hi'; // Import a modern icon for separators

interface BreadcrumbProps {
    pageName?: string;  // Optional prop to handle pages like settings or other tabs
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ pageName }) => {
    const { id } = useParams<{ id: string }>();  // Extract dynamic id from the URL
    const location = useLocation();  // Get current URL to determine the structure

    // Determine if we're in the inbox view or deeper
    const isInboxPage = location.pathname.includes(`/dashboard/inbox/${id}`);

    return (
        <nav aria-label="Breadcrumb">
            <ul className="flex items-center space-x-2 text-gray-700 text-base mb-4">
                {/* Inboxes Link */}
                <li>
                    <Link 
                        to="/dashboard/inboxes" 
                        className="flex items-center space-x-1 text-gray-500 hover:text-gray-300 transition duration-200">
                        <span>Inboxes</span>
                    </Link>
                </li>

                {/* Chevron Icon Separator */}
                <li>
                    <HiChevronRight className="text-gray-400" />
                </li>

                {/* Conditional Inbox link based on the presence of `id` */}
                {id && (
                    <>
                        <li>
                            {isInboxPage && pageName === 'Inbox' ? (
                                <span className="text-gray-900 font-bold">Inbox</span> // Not linked if it's the Inbox page itself
                            ) : (
                                <Link 
                                    to={`/dashboard/inbox/${id}`} 
                                    className="text-gray-500 hover:text-gray-300 transition duration-200">
                                    Inbox
                                </Link>
                            )}
                        </li>

                        {pageName !== 'Inbox' && (
                            <li>
                                <HiChevronRight className="text-gray-400" />
                            </li>
                        )}
                    </>
                )}

                {/* Append page name if it exists and it's not 'Inbox' */}
                {pageName && pageName !== 'Inbox' && (
                    <li>
                        <span className="text-gray-900 font-bold">{pageName}</span>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Breadcrumb;
