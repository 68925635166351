import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const CreateInboxForm: React.FC = () => {
    const [inboxName, setInboxName] = useState('');
    const [parseEngine, setParseEngine] = useState('None');
    const navigate = useNavigate();

    const allOptions: { label: string, value: string }[] = [
        { label: "GPT-4o (Text)", value: "gpt-4o" },
        { label: "GPT-4o (Vision)", value: "gpt-4o-v" },
        { label: "Claude Sonnet 3.5", value: "claude-sonnet-35" }
    ];

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (parseEngine === 'None') {
            alert("Please select a parse engine.");
            return;
        }

        const requestBody = {
            name: inboxName,
            engine: parseEngine
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BE_API_ENDPOINT}/api/inbox`, 
                requestBody,
                {
                    withCredentials: true,
                }               
            );
            if (response.status === 200) {
                alert("Inbox created successfully!");
                navigate('/dashboard/inboxes');
            } else {
                alert("Failed to create inbox.");
            }
        } catch (error) {
            console.error("Error creating inbox:", error);
            alert("An error occurred while creating the inbox.");
        }
    };

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-2xl p-6 bg-white shadow-md rounded-lg mr-auto">
                <h1 className="text-2xl font-bold mb-2">Create an Inbox</h1>
                <p className="mb-4 text-gray-600">You can use inboxes to gather and manage similar documents, such as PDF invoices or emails from Airbnb. If you need to sort various document types, simply set up additional inboxes for each category!
                    This approach allows for better organization and accessibility of your documents. For more tips on managing your documents effectively, you can check out additional resources.</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="inboxName">
                            Inbox Name
                        </label>
                        <p className="text-gray-600 text-sm mb-2">Give your inbox a name.</p>
                        <input
                            id="inboxName"
                            type="text"
                            value={inboxName}
                            onChange={(e) => setInboxName(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="parseEngine">
                            Parse Engine
                        </label>
                        <p className="text-gray-600 text-sm mb-2">Choose the engine for document parsing. The default option is our proprietary engines, but you also have the option to select any engines you've configured under Parse Engine. <b>You can change this later</b></p>
                        <select
                            id="parseEngine"
                            value={parseEngine}
                            onChange={(e) => setParseEngine(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                            required
                        >
                            <option value="None" className="italic">None</option>
                            {allOptions.length > 0 ? (
                                allOptions.map((option, index) => (
                                    <option key={index} value={option.value} className="italic">{option.label}</option>
                                ))
                            ) : (
                                <option value="" disabled className="italic">No engines configured</option>
                            )}
                        </select>
                    </div>
                    <div className="flex justify-between items-center">
                        <Link to="/dashboard/inboxes" className="btn btn-outline">
                            Back to Inboxes
                        </Link>
                        <button type="submit" className="btn bg-gray-700 hover:bg-gray-800 text-white rounded px-8">
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateInboxForm;