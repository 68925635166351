import React, { useState } from 'react';

import GeneralTab from './Tabs/General/GeneralTab';
import TeamTab from './Tabs/Team/TeamTab';
import SubscriptionTab from './Tabs/Subscription/SubscriptionTab';
import BillingTab from './Tabs/Billing/BillingTab';
import InvoicesUsageTab from './Tabs/InvoicesUsage/InvoicesUsageTab';

const Account: React.FC = () => {
    const [activeTab, setActiveTab] = useState('General');

    const General = 'General';
    const Team = 'Team';
    const Subscription = 'Subscription';
    const Billing = 'Billing';
    const InvoicesUsage = 'Invoices & Usage';

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
    };

    return (
        <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-2xl font-bold mb-4">Account & Settings</h2>
                <div className="border-b border-gray-300 mb-4">
                    <ul className="flex gap-6">
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === General ? 'border-b-4 border-gray-700 font-bold text-gray-700' : 'hover:text-gray-800'}`}
                            onClick={() => handleTabClick(General)}
                        >
                            General
                        </li>
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === Team ? 'border-b-4 border-gray-700 font-bold text-gray-700' : 'hover:text-gray-800'}`}
                            onClick={() => handleTabClick(Team)}
                        >
                            Team
                        </li>
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === Subscription ? 'border-b-4 border-gray-700 font-bold text-gray-700' : 'hover:text-gray-800'}`}
                            onClick={() => handleTabClick(Subscription)}
                        >
                            Subscription
                        </li>
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === Billing ? 'border-b-4 border-gray-700 font-bold text-gray-700' : 'hover:text-gray-800'}`}
                            onClick={() => handleTabClick(Billing)}
                        >
                            Billing
                        </li>
                        <li
                            className={`cursor-pointer py-2 px-4 transition-colors duration-300 ${activeTab === InvoicesUsage ? 'border-b-4 border-gray-700 font-bold text-gray-700' : 'hover:text-gray-800'}`}
                            onClick={() => handleTabClick(InvoicesUsage)}
                        >
                            Invoices & Usage
                        </li>
                    </ul>
                </div>
                {activeTab === General && <GeneralTab />}
                {activeTab === Team && <TeamTab />}
                {activeTab === Subscription && <SubscriptionTab />}
                {activeTab === Billing && <BillingTab />}
                {activeTab === InvoicesUsage && <InvoicesUsageTab />}
            </div>
        </>
    );
};

export default Account;
