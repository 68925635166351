import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './route/PrivateRoute';

// Import your components
import Home from './pages/Home/Home';
import SignupPage from './pages/Auth/Signup';
import SigninPage from './pages/Auth/Signin';
import ResetLoginPage from './pages/Auth/ResetLogin';
import ResetPasswordPage from './pages/Auth/ResetPassword';
import VerifyEmailConfirmation from './pages/Auth/Status/VerifyEmailConfirmation';

import NotFound from './pages/NotFound/NotFound';
import Inboxes from './pages/Postlogin/Inboxes/Inboxes';

import { useAuth } from './context/AuthContext';

// Import layout
import AppLayout from './components/AppLayout/AppLayout';
import Account from './pages/Postlogin/Account/Account';

import Inbox from './pages/Postlogin/Inboxes/Inbox/Inbox';
import InboxHome from './pages/Postlogin/Inboxes/Inbox/InboxHome/InboxHome';
import ParsedData from './pages/Postlogin/Inboxes/Inbox/ParsedData/ParsedData';
import InboxSetting from './pages/Postlogin/Inboxes/Inbox/InboxSetting/InboxSetting';
import Integrations from './pages/Postlogin/Inboxes/Inbox/Integrations/Integrations';

import Document from './pages/Postlogin/Inboxes/Inbox/InboxHome/Document/Document';
import CreateInboxForm from './pages/Postlogin/CreateInbox/CreateInbox';

const DashboardLayout: React.FC<{
  handleUpdateSidebarParam: (name: string, emailAlias: string) => void
  sidebarName: string,
  sidebarEmailAlias: string
}> = ({ handleUpdateSidebarParam, sidebarName, sidebarEmailAlias }) => (
  <PrivateRoute>
    <AppLayout
      handleUpdateSidebarParam={handleUpdateSidebarParam}
      sidebarName={sidebarName}
      sidebarEmailAlias={sidebarEmailAlias}
    >
      <Outlet />
    </AppLayout>
  </PrivateRoute>
);

// Debug component
const DebugAuth: React.FC = () => {
  const { isAuthenticated } = useAuth();
  console.log('isAuthenticated:', isAuthenticated);
  return null;
};

const App: React.FC = () => {

  // For Inbox page loading
  const [sidebarName, setSidebarName] = useState<string>('');
  const [sidebarEmailAlias, setSidebarEmailAlias] = useState<string>('');

  const handleUpdateSidebarParam = (name: string, emailAlias: string) => {
    setSidebarName(name);
    setSidebarEmailAlias(emailAlias);
  };

  return (
    <AuthProvider>
      <Router>
        <div>
          <DebugAuth />
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/signin" element={<SigninPage />} />
            <Route path="/reset" element={<ResetLoginPage />} />
            <Route path="/reset/change-password" element={<ResetPasswordPage />} />
            <Route path="/confirm" element={<VerifyEmailConfirmation />} />

            {/* Dashboard routes using Outlet */}
            <Route path="dashboard" element={
              <DashboardLayout
                handleUpdateSidebarParam={handleUpdateSidebarParam}
                sidebarName={sidebarName}
                sidebarEmailAlias={sidebarEmailAlias}
              />
            }>
              <Route index element={<Navigate to="inboxes" replace />} />
              <Route path="inboxes" element={<Inboxes />} />
              <Route path="account" element={<Account />} />

              {/* Hidden Routes */}
              <Route path="create-inbox" element={<CreateInboxForm />} />

              {/* Inbox routes */}
              <Route path="inbox/:id" element={<Inbox onUpdateSidebarParam={handleUpdateSidebarParam} />}>
                <Route index element={<InboxHome />} />
                <Route path="parsed-data" element={<ParsedData />} />
                <Route path="integrations" element={<Integrations />} />
                <Route path="settings" element={<InboxSetting />} />

                {/* Document Route */}
                <Route path="doc/:docId" element={<Document />} />
              </Route>
            </Route>

            {/* 404 route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
